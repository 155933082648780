import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./Janajohannes.css"
import { Typography, Grid, Container } from "@material-ui/core"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Jenny = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Jenny.JPG" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => 
      <div id="jenny">
                  <Container maxWidth="md" height="600px">
                  <Grid container spacing={2} direction="row" alignItems="center" justify= "center" style={{paddingTop: "10px"}}>
            <Grid item xs={6}>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
            </Grid>
            <Grid item xs={12}>

          
              <Typography variant="subtitle1" align="left" paragraph>
              Jenny kann einem manchmal echt leid tun… Jenny muss/musste mich bei jeglichen Entscheidungen unterstützen, Wohnungssuche, Frauen anschreiben, arbeitsmäßig, sind wir mal ehrlich die Liste ist lang, … aber beginnen wir mal von Anfang an. 
                  </Typography>

                  <Typography variant="subtitle1" align="left" paragraph>
                  Jenny habe ich während meines Studiums kennengelernt. Wir haben uns so gut verstanden, dass sie mich zu meinen Bachelor Abschluss begleitet hat. Über die Jahre entwickelte sich eine tiefe Freundschaft und ich würde so weit gehen zu sagen, dass Jenny meine zweite Familie geworden ist. Heute bin ich stolzer Patenonkel ihres zweiten Sohnes Leon. Sie hat mir die ruhige und schöne Bergwelt nähergebracht, während ich meinem 
            
                  Patenkind die neueste Apple-Technik zeigen darf. Wenn ich ein Problem habe, ist Jenny stets die Erste, zu der ich gehe. Sie hat immer ein offenes Ohr für mich, hört mir zu, gibt mir Ratschläge und zur Not auch den passenden Arschtritt. 
                  </Typography>
                  <Typography variant="subtitle1" align="left" paragraph>
                  Da man bei einer Hochzeit viele Arschtritte braucht – zum Beispiel, um nicht sofort einen Cocktailroboter zu mieten (leider) - war mir gleich klar, dass Jenny meine Trauzeugin sein soll. 
                  </Typography>
  
      
          </Grid>
         
        </Grid>
          </Container>
      
      
    </div>
    }
  />
)
export default Jenny
