import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "./Janajohannes.css"
import { Typography, Grid, Container } from "@material-ui/core"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dropbox from './Dropbox';


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.green,
    color: theme.palette.common.blue,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const rows = [
  createData('A - Anmeldung'),
  createData('Ohne euch wird das Fest nur halb so schön. Ungerne möchten wir auf eure Anwesenheit verzichten. Daher werden nur Zusagen akzeptiert 😉. Bitte gebt uns deswegen bis zum 15.08.2021 eine Rückmeldung, DASS ihr kommt!'),
  createData('B - Bunt'),
  createData('Unser Motto ist: Die Liebe ist eine bunte Reise!'),
  createData('C - Come Together'),
  createData('Unsere Hochzeit findet um 15 Uhr mit einem "Come Together" im Weldegarten statt 🍻. Den Hochzeitsablauf könnt ihr dem Zeitplan entnehmen.'),
  createData('D - Dresscode'),
  createData('Gemäß unseres Mottos: Die Liebe ist eine bunte Reise. Wir würden uns freuen, euch in legerer, festlicher und bunter Kleidung begrüßen zu dürfen. Anzüge sind im Übrigen verboten. P.S.: Bequeme Schuhe sind von Vorteil, im Weldegarten gibt es Kiesboden im Außenbereich 😃'),
  createData('E - Erinnerungsfotos'),
  createData('Wir wollen unsere gemeinsamen Momente noch mal mit euch Revue passieren lassen. Wir würden gerne Fotos an der Hochzeit zeigen. Habt ihr Bilder, die euch an uns erinnern, könnt ihr uns diese gerne im Vorfeld schicken 😃. Alternativ könnt ihr sie auch direkt in unsere Dropbox hochladen (einfach auf das Dropbox Logo unter der Tabelle klicken).'),
  createData('F - Flitterwochen'),
  createData('Wenn es Corona zulässt, verbringen wir unsere Flitterwochen auf Bali 🏝.'),
  createData('G - Geschenke'),
  createData('Geschenke sind nicht notwendig. Wir freuen uns, wenn ihr diesen Tag mit uns verbringt und die Anreise auf euch nehmt. Wenn ihr trotzdem etwas schenken möchtet, würden wir uns über eine gemeinsame Aktivität freuen.  Wenn ihr nichts mit uns machen wollt, ist Geld auch in Ordnung 💰.'),
  createData('H - Hochzeitsfotos'),
  createData('Fotografieren ist nicht nur erlaubt, sondern ausdrücklich erwünscht. Zur Sicherheit haben wir aber auch noch einen professionellen Fotografen, der uns den ganzen Tag begleitet. Wir stellen sie euch anschließend hier auf der Website zur Verfügung 📸!'),
  createData('I - Ich und du'),
  createData('– daraus wird bald ein wir.'),
  createData('J - Jenny und Johannes'),
  createData('❤️'),
  createData('K - Kleinen'),
  createData('Für die Kleinen haben wir eine Hüpfburg und eine Spieleecke bei schlechtem Wetter.'),
  createData('L - Liederwunsch'),
  createData('Liederwünsche könnt ihr uns im Vorfeld gerne zukommen lassen.'),
  createData('M - Musik'),
  createData('Die Band wird für richtig gute Musik zum Feiern sorgen 🎸.'),
  createData('N - Nachtisch'),
  createData('Die Nachtisch-Challenge werden wir auf jeden Fall  gewinnen 💪.'),
  createData('O - Open End'),
  createData('Wir möchten so lange wie möglich unseren Tag mit euch feiern. Bis spätestens um 10 Uhr am nächsten Morgen 😃.'),
  createData('P - Probeessen'),
  createData('Probeessen waren wir schon und wir können euch versprechen: Es wird lecker! Wir haben ein Buffet, daher wird für alle etwas dabei sein. Hunger muss niemand befürchten. Allergien möchten wir gerne berücksichtigen, daher könnt ihr uns das gerne im Vorfeld mitteilen.'),
  createData('Q - Quartier'),
  createData('Übernachten könnt ihr im Rheinauerhof, Berliner Hotel oder in Neumanns Pension. Nähere Informationen findet ihr unten 🛎.'),
  createData('R - Ringe'),
  createData('Unsere Ringe haben wir selbst geschmiedet 💍💍.'),
  createData('S - Sonnenschein'),
  createData('Sonnenschein ist bestellt. Wir hoffen auf eine pünktliche Lieferung 🌞.'),
  createData('T - Trauzeugen'),
  createData('Unsere Trauzeugen sind Jenny und Christian. Habt ihr eine kreative Idee?  Sprecht Ideen für Spiele oder andere Beiträge bitte mit unseren Trauzeugen ab.'),
  createData('U - Unser Niffler'),
  createData('Selbstverständlich wird unser Niffler mit dabei sein 😃.'),
  createData('W - Warum'),
  createData('Warum wir heiraten!? LIEBE 💕.'),
  createData('XY - ungelöst'),
  createData('Falls Ihr noch Fragen habt, ruft uns gerne an.'),
  createData('Y - Yeah'),
  createData('Yeah - endlich heiraten wir!'),
  createData('Z - Zum Schluss'),
  createData('Zum Schluss schon einmal vielen Dank für eure Unterstützung und dass ihr diesen besonderen Tag mit uns verbringen werdet 🥰.'),
];

const ABC = () => (

      <div id="abc">
                  <Container maxWidth="md" height="600px">
                  <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
          
            <StyledTableCell><div style={{padding:10}}>Hochzeits ABC</div></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                <div style={{padding:10}}>{row.name}</div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
          </Container>
      
      <Dropbox></Dropbox>
    </div>
    
)
export default ABC
