import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./Janajohannes.css"
import { Typography, Grid, Container } from "@material-ui/core"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const BrautpaarVorstellung = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Brautpaar.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => 
      <div id="BrautpaarVorstellung">
  <Container maxWidth="md" height="600px">
            <Grid container spacing={2} direction="row" alignItems="center" justify= "center" style={{paddingTop: "10px"}}>
            <Grid item xs={9}>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
            </Grid>
            <Grid item xs={12}>
          
            <Typography variant="subtitle1" align="left" paragraph>
              It's a match!
              </Typography>
              <Typography variant="subtitle1" align="left" paragraph>
Viele von euch fragen sich sicherlich, wie wir uns kennengelernt haben. Einfache Antwort: ganz klassisch über Tinder. Doch beginnen wir Anfang November 2018 auf dem Bildschirm der beiden Handys. Plötzlich ploppt ein "It's a match" auf. Im Anschluss darauf befindet sich auch schon eine Nachricht in meinem Posteingang. Die Nachricht war von Johannes. Nach kurzem Hin und Her schreiben, analysieren und mit einem Lächeln auf die nächste Nachricht warten, haben wir uns am 12.11.2018 zum Gleamgolf (Schwarzlichtminigolf) spielen getroffen. So fängt unsere gemeinsame Geschichte an…  


                  </Typography>
                  <Typography variant="subtitle1" align="left" paragraph>
                  Nach ganzen zwei Wochen mit Dates und viel weiterem hin- und herschreiben, habe ich am 1.12.2018 Johannes eine Sprachnachricht geschickt. Der Inhalt der Sprachnachricht war in etwa: Im Übrigen, wir sind jetzt zusammen! 01.12. das Datum kann ich mir gut für unseren Jahrestag merken. Ich hoffe, es ist okay für dich! Johannes hat zum Glück wohlwollend zugestimmt. 

                  </Typography>

                  <Typography variant="subtitle1" align="left" paragraph>
                  Mit der Zeit wurde meine Wohnung in Eppelheim immer leerer und Johannes Wohnung in Hockenheim immer voller 😀. Nachdem es auch Johannes aufgefallen war, dass ich mittlerweile quasi bei ihm wohne, war der offizielle Umzug von Eppelheim nach Hockenheim am 01.09.2019 😀. Kurz danach ist auch Niffi mit in die Wohnung gezogen, der uns ständig auf Reisen begleitet und leidenschaftlich gerne Urlaubseuros ernifflert 😀.
                  </Typography>
                  <Typography variant="subtitle1" align="left" paragraph>
                  Die Verlobungsgeschichte kennt hier bestimmt jeder. Zur kurzen Wiederholung hier noch mal der Schnelldurchlauf: Wir waren in Slowenien (Bled) und haben dort in einem Baumhaus übernachtet. Den Abend wollten wir gerne mit einer Massage ausklingen lassen. Ich hatte die zweite Massage, so konnte Johannes mit Hilfe von Niffi alles unbemerkt vorbereiten. Bei Kerzenlicht und mit Rosen ist Johannes dann auf die Knie gefallen und hat gefragt, ob ich seine Frau werden möchte. Meine erste Reaktion war „Nein, nein, nein. Du verarschst mich jetzt nicht, nein“. Nachdem er ein zweites Mal auf die Knie gefallen ist und mich noch mal gefragt hat, habe ich gesagt: „Ja, ich will!“ Wenn man uns fragen würde: 'Was war das Schönste an der Verlobung?' Oder: 'Was ist das Schönste an eurer Beziehung allgemein?' dann könnten wir sicherlich vieles aufzählen. Doch das Allerschönste und Wichtigste ist, dass wir so viele wunderschöne Momente – vor allem auch mit euch – erlebt haben. Daher würden wir euch auch gerne an unserem großen Tag dabei haben 😍!
                  </Typography>
                  <Typography variant="subtitle1" align="left" paragraph>
                  Liebe Grüße,
            
                   </Typography>
                   <Typography variant="subtitle1" align="left" paragraph>
                     
                   Jenny und Johannes
                   </Typography>
                  </Grid>
      
  
    
        </Grid>
          </Container>
      
    </div>
    }
  />
)
export default BrautpaarVorstellung
