import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./Janajohannes.css"
import { Typography, Grid, Container } from "@material-ui/core"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const HockenheimRing = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Hockenheimring.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => 
      <div id="hockenheimring">
                  <Container maxWidth="md" height="600px">
            <Grid container spacing={2} direction="row" alignItems="center" justify= "center">
            <Grid item xs={12} sm={6}>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
        </Grid>
        <Grid container item xs={12} sm={6} spacing={3} direction="row" alignItems="center" justify= "center">
          
              <Typography variant="subtitle1" align="left" paragraph>
              Natürlich gibt es auch in Hockenheim einiges zu erleben. Last but not least lädt der Hockenheim-Ring mit seinen Museen zur Erkundung ein. 

                  </Typography>
  
      
          </Grid>
        </Grid>
          </Container>
      
      
    </div>
    }
  />
)
export default HockenheimRing
