import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import "./Features.css"

import IconButton from '@material-ui/core/IconButton';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

 
const Dropbox= () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Dropbox.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}

    
    render={data => 
  
                  <Link href="https://www.dropbox.com/request/hd1FB1PxoTku8rSPcvmY" target="_blank" rel="noopener"> 
            <IconButton color="primary" aria-label="instagram" component="span">
            <Img style={styles} fluid={data.placeholderImage.childImageSharp.fluid} />

        </IconButton>
        </Link>
    
    }
  />
  
)

const styles = {
width: "300px",
height: "94px"
};

export default Dropbox
