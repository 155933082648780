import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./Janajohannes.css"
import { Typography, Grid, Container } from "@material-ui/core"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const ChristianQR = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "ChristianQR.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => 
      <div id="christianQR">
                  <Container maxWidth="md" height="100px">
                  <Grid container spacing={2} direction="row" alignItems="center" justify= "center" style={{paddingTop: "10px"}}>
                  
            <Grid item xs={6}>
            <Typography variant="subtitle1" align="center" paragraph>
             Kontaktdaten (017683339961) 
             </Typography>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
            </Grid>
    
        </Grid>
          </Container>
      
      
    </div>
    }
  />
)
export default ChristianQR
