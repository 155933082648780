import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./Janajohannes.css"
import { Typography, Grid, Container } from "@material-ui/core"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Christian = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Christian.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => 
      <div id="christian">
                  <Container maxWidth="md" height="600px">
                  <Grid container spacing={2} direction="row" alignItems="center" justify= "center" style={{paddingTop: "10px"}}>
            <Grid item xs={6}>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
            </Grid>
            <Grid item xs={12}>

          
             
                  <Typography variant="subtitle1" align="left" paragraph>
                  Christian habe ich an der Hochschule kennengelernt. Seitdem wir uns kennen, haben wir ein paar Reisen zusammen unternommen. In Sachen Roadtrip in Nordamerika macht uns so schnell niemand was vor 😀. Schneestürme in Chicago, grandiose Ausblicke über den Grand Canyon, eiskalte Nächte im Zelt in den kanadischen Rocky Mountains und das wilde Treiben von New York erleben. Dazu gesellen sich noch weitere Abenteuer wie der Videodreh im karibischen Meer vor der Küste Panamas oder Falafel essen in Tel Aviv. Solche außergewöhnlichen Zeiten schweißen natürlich zusammen, aber nicht nur die, sondern auch die ganz normalen Gespräche im Alltag sind immer sehr erheiternd und inspirierend. 

                  </Typography>

                  <Typography variant="subtitle1" align="left" paragraph>
                  Auch heute noch verstehen wir uns (fast) blind bei vielen Gesprächen. Daher war für mich relativ schnell klar, dass Christian mein Trauzeuge werden soll. 
             </Typography>

          
          </Grid>
        </Grid>
          </Container>
      
      
    </div>
    }
  />
)
export default Christian
