import React from "react";
import MailChimpForm from "../components/form";
import SEO from "../components/seo";
import Layout from "../components/layout";
import {Link} from 'gatsby'
import { Helmet } from "react-helmet"

import CookieConsent from "react-cookie-consent"

import "./bootstrap.min.css"
import "./now-ui-kit.css"

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import { createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import BrautpaarVorstellung from '../components/BrautpaarVorstellung';
import Instagram from '../components/Instagram'
import Facebook from '../components/Facebook'


import Christian from '../components/Christian';
import ChristianQR from '../components/ChristianQR';
import Jenny from '../components/Jenny'
import JennyQR from '../components/JennyQR'
import SchlossHeidelberg from '../components/SchlossHeidelberg'
import Heidelberg from '../components/Heidelberg'
import HockenheimRing from '../components/HockenheimRing'
import ABC from '../components/ABC'
import Weldegarten from '../components/Weldegarten'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },

  features: {
    backgroundColor: '#fb8c00',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  featureshell: {
    backgroundColor: '#90ee90',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  logo: {
    maxWidth: '400px',
    margin: 'auto',
    paddingTop: '6rem',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const theme = createMuiTheme({
      palette: {
        primary: {
          main:'#FFFFFF',
          contrastText: "#54585a" //button text white instead of black
        },
        secondary: {
          main: '#ff6900',
        },
      },
    });

function IndexPage () {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Helmet>
                <meta
                    name="robots"
                    content="noindex"
                />
            </Helmet>
      <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Traumhochzeit Jenny & Johannes" 
        description="Hochzeit" 
        keywords={[`Hochzeit`]} />

        <div id="BrautpaarVorstellung" className="col-12" style={{paddingTop: "120px"}}>
          <div className={classes.featureshell}> 
          <h2 ><center>Vorstellung des Brautpaares</center></h2>
          <Container maxWidth="md" height="600px">
             <BrautpaarVorstellung></BrautpaarVorstellung>
          </Container>
          </div>
        </div>

    

        <div id="tasche" className="col-12" >
        <div className={classes.features}> 
        <h2><center>Trauzeugen</center></h2>
        <Container maxWidth="md" height="600px">
        <Typography variant="subtitle1" align="left" paragraph>
              Unsere Trauzeugen organisieren das Programm am Hochzeitstag. Wenn ihr euch an der Programmgestaltung der Feier beteiligen möchtet, lassen wir uns gerne überraschen. Zur besseren Organisation bitten wir euch allerdings, mit unseren Trauzeugen Rücksprache zu halten. Dass ihr wisst, mit wem ihr es zu tun habt, stellen wir unsere Trauzeugen kurz vor. 

                  </Typography>
        </Container>
        <h2><center>Christian</center></h2>
        <Container maxWidth="md" height="600px">
            <Christian></Christian>
            </Container>
            <Container maxWidth="sm" height="100px">
            <ChristianQR></ChristianQR>
        </Container>
        </div>

        <div className={classes.featureshell}> 
        <h2><center>Jenny</center></h2>
        <Container maxWidth="md" height="600px">
            <Jenny></Jenny>
        </Container>
        <Container maxWidth="sm" height="100px">
            <JennyQR></JennyQR>
        </Container>
        </div>

        <div id="location" className={classes.features} style={{paddingTop: "70px"}}> 
        <h2><center>Die Location </center></h2>

      

        <Container maxWidth="md" height="600px">
            <Grid container item xs={12} spacing={3} direction="row" alignItems="center" justify= "center">
            <Grid item xs={6}>
            <Weldegarten></Weldegarten>
            </Grid>
            <Grid item xs={6}>
            <Typography variant="h6" align="left" paragraph>
            <Link target="_blank" to='https://g.page/weldegarten?share' style={{ color: 'white' }}>Weldegarden </Link>
            </Typography>
            <Typography variant="subtitle1" align="left" paragraph>
            Brauereistraße 1, 68723 Plankstadt 
               </Typography>
               </Grid>

        </Grid>
        </Container>
        </div>

        <div className={classes.featureshell} style={{paddingTop: "70px"}}> 
        <h2><center>Zeitplan </center></h2>
        <h3><center>Samstag, den 18.09.2021 </center></h3>
        <Container maxWidth="md" height="600px">
            <Grid container item xs={12} spacing={3} direction="row" alignItems="center" justify= "center">
          
  
          
               <List>
            <ListItem>
                  <ListItemText
                    primary="🎂 15 Uhr: Beginn mit Kaffee / Kuchen und Crêpes "
                  />
                </ListItem>
              <ListItem>
                  <ListItemText
                    primary="🍝 18 Uhr: Essen"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="🥳 20 Uhr: Band"
                  />
                </ListItem>
                  <ListItem>
                  <ListItemText
                    primary="🥓 22 Uhr: Mitternachtssnack"
                  />
                </ListItem>
          </List>

                <Typography>
                Wie ihr seht, wir sind mit Essen gut ausgestattet 😀. Bringt also viel Hunger mit 😀!
                </Typography>

        </Grid>
        </Container>
        </div>

        
        <div className={classes.features}> 
        <h2><center>Ausflugstipps</center></h2>
        <Container maxWidth="md" height="600px">

          <Typography style={{paddingBottom: "15px"}} >Wenn ihr von weiter wegkommt und noch nicht so ganz genau wisst, was ihr neben unserer Hochzeit noch so erleben könnt. Hier ein paar Tipps:
</Typography>

            <SchlossHeidelberg></SchlossHeidelberg>
            <Heidelberg></Heidelberg>
            <HockenheimRing></HockenheimRing>
        </Container>
        </div> 

        <div id= "abc" className={classes.featureshell}> 
        <h2><center>Hochzeits ABC</center></h2>
        <Container maxWidth="md" height="600px">
            <ABC></ABC>
        </Container>
        </div>

        <div className={classes.features}> 
        <h2><center>Unterkunft</center></h2>
        <Container maxWidth="md" height="600px">
        <Typography variant="subtitle1" align="left" paragraph>
            <Link target="_blank" to='https://neumann-uebernachtung.de/' style={{ color: 'white' }}>Neumanns </Link>
             ist direkt um die Ecke und fußläufig zu erreichen
               </Typography>
               <Typography variant="subtitle1" align="left" paragraph>
            <Link target="_blank" to='https://www.hotel-berlin-schwetzingen.de/en' style={{ color: 'white' }}>City Hotel Berlin </Link>
             ist in Schwetzingen ca. 3 km vom Weldegarten entfernt 
               </Typography>
               <Typography variant="subtitle1" align="left" paragraph>
            <Link target="_blank" to='https://www.rheinauerhof.de' style={{ color: 'white' }}>Rheinauerhof </Link>
             ist in Rheinau (Mannheim) ca. 7 km vom Weldegarten entfernt 
               </Typography>

        </Container>
        </div>

        <div className={classes.featureshell}> 
        <Container maxWidth="md" height="600px">
            <Grid container item xs={12} spacing={3} direction="row" alignItems="center" justify= "center">
            <h2><center>Sponsered by</center></h2>
<Instagram>
</Instagram>
<Facebook></Facebook>

        </Grid>
        </Container>
        </div> 
        </div>
        <div>
        </div>
        </Layout>
        </ThemeProvider>
      </React.Fragment>
  )
};

export default IndexPage;
